import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import ActionButtons from './ActionButtons';
import ImageWithCTA from './ImageWithCTA';
import GameInfo from './GameInfo';
import LotterySection from './LotterySection';

const Homepage = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    // }, []);

    return (
        <div style={{ backgroundColor: ConstantStyles['light-grey'] }}>
            <div style={{
                backgroundColor: ConstantStyles['light-grey'],
                margin: "0 auto",
                maxWidth: "1440px"
            }} >
                <ActionButtons />
                <ImageWithCTA />
                {/* <LotterySection /> */}
                <br />
                <GameInfo />

                {/* 
            <AccreditedLogo />
            <TopGames />
            <ContactUs />
            <Footer /> */}
            </div >
        </div>
    )


}


export default Homepage;