import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import CustomTable from "../../shared/CustomTable";
import CustomButton from "../../shared/CustomButton";

import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import {
    Box,
    Grid,
    Card,
    CardContent,
    CardActions
} from '@mui/material';


const Cart = () => {
    const globalStates = useHookState(GlobalStates);
    const [cart, setCart] = useState((localStorage.getItem("cart")) ?? []);
    const [tableData, setTableData] = useState([]);
    const [noCards, setNoCards] = useState("");
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [amount, setAmount] = useState("");
    const [draws, setDraws] = useState("");




    const getTableHeaders = useMemo(() => {
        return [
            { id: "bet", name: "Bet Details", sortable: true }
        ];
    }, []);

    useEffect(() => {
        if (cart.length !== 0) {
            let newCart = JSON.parse(cart);
            let newList = newCart.cards.map(col => {
                return {
                    bet:
                        <Grid container justifyContent="" spacing={2} p={{ xs: 2, sm: 4 }} style={{ paddingTop: "0px", paddingBottom: "0" }} >
                            <Grid item xs={12} sm={2}>
                                <b>Card #  {parseFloat(col.cardNo) + 1}</b>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <b>Primary bet</b>
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: ConstantStyles['almost-red'],
                                            color: "#fff",
                                            fontSize: "14px",
                                            padding: "3% 2%",
                                            height: "100%",
                                            borderRadius: "50%",
                                            width: "12%",
                                            display: "inline-block",
                                            textAlign: "center",
                                            fontWeight: ConstantStyles['bold']
                                        }}
                                    >{col.firstNoRed?.[0]}</div>
                                    &nbsp;

                                    <div
                                        style={{
                                            backgroundColor: ConstantStyles['almost-blue'],
                                            color: "#fff",
                                            fontSize: "14px",
                                            padding: "3% 2%",
                                            height: "100%",
                                            borderRadius: "50%",
                                            width: "12%",
                                            display: "inline-block",
                                            textAlign: "center",
                                            fontWeight: ConstantStyles['bold']
                                        }}
                                    >{col.firstNoBlue?.[0]}
                                    </div>
                                </div>
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={4}>
                                <b>Side bet(s)</b>
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: "#f8b12c",
                                            color: "#fff",
                                            fontSize: "14px",
                                            padding: "3% 2%",
                                            height: "100%",
                                            borderRadius: "50%",
                                            width: "12%",
                                            display: "inline-block",
                                            textAlign: "center",
                                        }}
                                    >{col.singleBet?.[0] ?? "-"}
                                    </div>


                                    <p style={{
                                        fontSize: "13px",
                                        textAlign: "left",
                                        width: "100%"
                                    }}>
                                        {col.oddEvenBet}
                                    </p>
                                    <p style={{
                                        fontSize: "13px",
                                        textAlign: "left",
                                        width: "100%"
                                    }}>
                                        {col.rangeBet}
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <b>Remove</b>
                            </Grid>

                        </Grid>
                }
            });
            let newTotal = ((parseFloat(newCart?.noOfcards) * parseFloat(newCart?.betAmount)) * parseFloat(newCart?.draws || 1))
            let discountedTotal = newTotal;
            if (newCart?.discount) {
                discountedTotal = newTotal - (parseFloat(newCart?.discount) * newTotal)
            }
            setTableData(newList)
            setNoCards(newCart?.noOfcards)
            setTotal(discountedTotal)
            setDiscount(newCart?.discount ?? 0)
            setAmount(newCart?.betAmount)
            setDraws(newCart?.draws ?? 1)
        }
    }, []);

    return (
        <div style={{ backgroundColor: ConstantStyles['light-grey'] }}>
            <Box sx={{
                backgroundColor: ConstantStyles['light-grey'],
                margin: "0 auto",
                maxWidth: "1440px",
                // paddingLeft: { xs: "0px", sm: "0px", md: "220px" },
                minHeight: "850px",
                paddingTop: "20px",
                paddingBottom: "140px"
            }} >
                <Grid container justifyContent="" spacing={2} p={{ xs: 2, sm: 4 }} style={{ paddingTop: "0px", paddingBottom: "0px" }} >

                    <Grid item xs={12} sm={9}>
                        <Card sx={{ maxWidth: "100%" }} style={{
                            backgroundColor: ConstantStyles.white,
                            color: ConstantStyles['dark-grey'], boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "14px", minHeight: "590px", maxHeight: "790px"
                        }}>
                            <CardContent
                            >
                                <CustomTable
                                    headers={getTableHeaders}
                                    data={tableData}
                                    order={"desc"}
                                    // orderBy={"userId"}
                                    noDataText={"No Record(s) Found"}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Card sx={{ maxWidth: "100%" }} style={{
                            backgroundColor: ConstantStyles.white,
                            color: ConstantStyles['dark-grey'], boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "14px"
                        }}>
                            <CardContent
                                style={{ minHeight: "530px", maxHeight: "530px" }}
                            >
                                <h3>Summary</h3>
                                <hr />
                                <p style={{ fontSize: "20px", marginBottom: "12px" }}>

                                    <span>No. of Card(s):</span> <span style={{ float: "right" }}><b>{noCards}</b></span>

                                </p>
                                <p style={{ fontSize: "20px", marginBottom: "12px" }}>

                                    <span>Amount per card: </span> <span style={{ float: "right" }}><b>{amount.toLocaleString("en-US", { style: "currency", currency: "Php" })}</b></span>

                                </p>
                                <p style={{ fontSize: "20px", marginBottom: "12px" }}>

                                    <span>No. of Draw(s): </span> <span style={{ float: "right" }}><b>{draws}</b></span>

                                </p>
                                <p style={{ fontSize: "20px", marginBottom: "12px" }}>
                                    <span>Discount: </span> <span style={{ float: "right" }}>
                                        {discount !== 0 ?
                                            <b>{(parseFloat(discount) * 100)} %</b>
                                            :
                                            null
                                        }

                                    </span>


                                </p>
                                {/* <p style={{ fontSize: "20px", marginBottom: "12px" }}>Total Amount: <b>{total.toLocaleString("en-US", { style: "currency", currency: "Php" })}</b> </p> */}
                            </CardContent>
                            <CardActions>
                                <CustomButton
                                    buttonLabel={"Pay Now " + total.toLocaleString("en-US", { style: "currency", currency: "Php" })}
                                    // onClick={() => handleLogout()}
                                    disabled={cart.length !== 0 ? false : true}
                                    type="submit"
                                    customSX={{
                                        width: "100%",
                                        padding: "5px",
                                        fontSize: "20px",
                                        color: ConstantStyles.black,
                                        border: ConstantStyles['default-border'],
                                        backgroundColor: ConstantStyles.white,
                                        verticalAlign: "top",
                                        "&:hover": {
                                            backgroundColor: ConstantStyles.primary,
                                            color: ConstantStyles.white,
                                            boxShadow: "none"
                                        },
                                    }}
                                /></CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )


}


export default Cart;