import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import { useState, useEffect, useMemo } from "react";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import SiteLogo from '../../../assets/img/bredas_logo.png';
import LotteryForms from './LotteryForm/LotteryForms';
import CustomAutocompleteSelect from '../../shared/CustomAutocompleteSelect';
import DrawList from '../../../utils/DrawCount.json';
import CustomCircularProgress from '../../shared/CustomCircularProgress';

import {
    Button,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from '@mui/material';


function upsert(array, element) { // (1)
    const i = array.findIndex(_element => _element.id === element.id);
    if (i > -1) array[i] = element; // (2)
    else array.push(element);
}

const LotterySection = () => {
    const globalStates = useHookState(GlobalStates);
    const [count, setCount] = useState(1);
    const [ticketType, setTicketType] = useState("Single");
    const [checked, setChecked] = useState(true);
    const [draw, setDraw] = useState(null);
    const [clearTicket, setClearTicket] = useState(false);
    const [quickPlay, setQuickPlay] = useState("");
    const [quickPower, setQuickPower] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [payloads, setPayloads] = useState([]);
    const [finalArray, setArray] = useState([]);
    const [cardAmount, setCardAmount] = useState(10);

    // array

    // useEffect(() => {
    //     console.log(payloads)
    // }, [count]);


    const pull_data = (data) => {
        const newArr = [...payloads];
        newArr.push(data)
        upsert(newArr, data)
        setPayloads(newArr)
    }

    const handlePickAll = () => {
        setIsLoading(false)
        let min = 1;
        let max = 38;
        let minPower = 1;
        let maxPower = 38;
        let randomNumber = [];
        let randomNumberMax = [];
        for (let i = 0; i < count; i++) {
            let numbers = [];
            for (let j = 0; j < 2; j++) {
                // console.log(Math.round(Math.random() * (max - min) + min))
                numbers.push(
                    Math.round(Math.random() * (max - min) + min)
                );
            }
            randomNumber.push({ numbers })
        }


        for (let i = 0; i < count; i++) {
            let numbersMax = [];
            numbersMax.push(
                Math.round(Math.random() * (maxPower - minPower) + minPower)
            );
            randomNumberMax.push({ numbersMax })
        }

        setQuickPower(JSON.stringify(randomNumberMax))
        setQuickPlay(JSON.stringify(randomNumber))

    }

    const ticketTypeHandle = (event) => {
        setTicketType(event.target.value)
    }

    const handleClearTickets = () => {
        setClearTicket(!clearTicket)
        setPayloads([])
    }

    const modifyCards = (type) => {
        let newCount = count;
        if (type === "More") {
            newCount = count + 1;
            setIsLoading(true)
        }
        else {
            newCount = count - 1;
            setIsLoading(true)
            // alert(count)
            function getUniqueListBy(data, key) {
                return [
                    ...new Map(data.map((item) => [item[key], item])).values(),
                ];
            }
            const arr1 = getUniqueListBy(payloads, "cardNo");


            let testSet = arr1.map((col, index) => {
                return {
                    id: index,
                    cardNo: col.cardNo,
                    firstNoBlue: col.firstNoBlue,
                    firstNoRed: col.firstNoRed,
                    oddEvenBet: col.oddEvenBet,
                    rangeBet: col.rangeBet,
                    singleBet: col.singleBet

                }
            });
            if ((testSet.some(testSet => testSet.cardNo === parseFloat(newCount - 1)))) {
                testSet = testSet.filter(function (item) {
                    return item.cardNo !== newCount;
                });
                setPayloads(testSet)
            }
        }
        setIsLoading(true)
        setCount(newCount)
        setChecked(!checked)

    }

    const handleSubmit = () => {
        function getUniqueListBy(data, key) {
            return [
                ...new Map(data.map((item) => [item[key], item])).values(),
            ];
        }
        const arr1 = getUniqueListBy(payloads, "cardNo");

        let testSet = arr1.map((col, index) => {
            if (col.firstNoBlue.length > 0 && col.firstNoRed.length > 0) {
                return {
                    Card: index + 1,
                    cardNo: col.cardNo,
                    firstNoBlue: col.firstNoBlue,
                    firstNoRed: col.firstNoRed,
                    oddEvenBet: col.oddEvenBet,
                    rangeBet: col.rangeBet,
                    singleBet: col.singleBet

                }
            }
            else {
                return {
                    Card: null,
                    cardNo: col.cardNo,
                    firstNoRed: undefined,
                    firstNoBlue: undefined

                }
            }
        });

        if (testSet.some(testSet => testSet.firstNoRed === undefined)) {

            testSet = testSet.filter(function (item) {
                return item.Card !== null;
            });
            setArray(testSet)
        }
        else if (testSet.some(testSet => testSet.firstNoBlue === undefined)) {

            testSet = testSet.filter(function (item) {
                return item.Card !== null;
            });
            // console.log("bl/ue");
            setArray(testSet)
        }
        else {
            // alert("Call API");
            setArray(testSet);
        }

        if (testSet.length !== 0) {
            let submitPayload = {
                userId: "1",
                checkoutReference: Math.round(Math.random() * (9999 - 0) + 0),
                cards: testSet,
                betAmount: cardAmount,
                noOfcards: testSet.length,
                draws: draw?.value,
                discount: parseFloat(draw?.discount),
                drawQty: ticketType
            }
            let existingCart = [...payloads]
            existingCart.push(submitPayload)
            // console.log(existingCart)
            localStorage.setItem('cart', JSON.stringify(submitPayload))

            if (localStorage.getItem("username")) {
                window.location.href = "/cart";
            }
            else {
                window.location.href = "/login";
            }

        }
        else {
            alert("Not Proceeding")
        }
    }

    const renderDraws = useMemo(() => {
        return DrawList.map((col, index) => {
            return { value: col.value, label: col.label, key: index, discount: col.discount }
        });
    }, []);

    return (
        <Grid container justifyContent="center" spacing={2} p={{ xs: 2, sm: 8 }} style={{ paddingTop: "25px" }}  >

            <Grid item xs={12} sm={12} id="section_1">
                <Card sx={{ maxWidth: "100%" }} style={{
                    backgroundColor: ConstantStyles.white,
                    color: ConstantStyles['dark-grey']
                }}>
                    <Grid container spacing={2} p={{ xs: 2, sm: 4 }} style={{ backgroundColor: ConstantStyles['white'] }} >
                        <Grid item xs={12} sm={2}>

                            <img src={SiteLogo} width="150" />
                        </Grid>

                        <Grid item xs={12} sm={7}>
                            <h4>Play Powerball Lottery Online – Buy Powerball Tickets</h4>
                            <h3>Php 306,605,000</h3>
                        </Grid>

                        <Grid item xs={12} sm={3} p={0}>
                            <p style={{ width: "90%" }}>Draw in 1 days 22 hours 09 minutes</p>
                            <br />
                            <div style={{ width: "100%", display: "block", textAlign: "center" }}>
                                <Button size="medium" variant="contained" color="primary"
                                    sx={{
                                        color: ConstantStyles['white'],
                                        display: "inline-block",
                                        textAlign: "center",
                                        borderRadius: "0",
                                        width: "60%",
                                        margin: "0 5px",
                                        fontSize: "16px"
                                    }}
                                    id={"PickAll"}
                                    style={{ backgroundColor: ConstantStyles.secondary }}
                                    onClick={handlePickAll}
                                >Quick Pick All</Button>
                                <Button size="medium" variant="contained" color="primary"
                                    sx={{
                                        color: ConstantStyles['white'],
                                        display: "inline-block",
                                        textAlign: "center",
                                        borderRadius: "0",
                                        width: "30%",
                                        margin: "0 5px",
                                        fontSize: "16px"
                                    }}
                                    style={{ backgroundColor: ConstantStyles.secondary }}
                                    onClick={handleClearTickets}
                                > Clear</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center"  >
                        {(() => {
                            const arr = [];
                            for (let i = 0; i < count; i++) {
                                arr.push(
                                    <LotteryForms index={i} isLoading={isLoading} checked={checked} clearTicket={clearTicket} quickPower={quickPower} quickPlay={quickPlay} newCount={count} func={pull_data} />
                                );
                            }
                            return arr;
                        })()}
                    </Grid>

                    <Grid container spacing={1} p={{ xs: 2, sm: 4 }} style={{ backgroundColor: ConstantStyles['white'] }} >
                        <Grid item xs={12} sm={12} style={{ border: ConstantStyles['default-border'] }}>
                            <p
                                style={{
                                    fontWeight: ConstantStyles['bold'],
                                    fontSize: "15px"
                                }}
                            >Choose the type of ticket</p>

                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={ticketType}
                                    onChange={ticketTypeHandle}
                                >
                                    <FormControlLabel value="Single" control={<Radio
                                    />} label="Single" />
                                    <FormControlLabel value="Multiple" control={<Radio
                                    />} label="Multiple" />
                                </RadioGroup>
                            </FormControl>
                            {ticketType === "Multiple" ?
                                <div style={{ marginBottom: "20px", width: "95%", padding: "0 15px" }}>
                                    <CustomAutocompleteSelect
                                        options={renderDraws}
                                        value={draw}
                                        key={renderDraws.index}
                                        onChange={setDraw}
                                        placeholder={"Multiple Draws"}
                                    />
                                </div>
                                :
                                null
                            }

                        </Grid>

                    </Grid>

                    <Grid container p={{ xs: 2, sm: 4 }} sx={{ backgroundColor: ConstantStyles['white'] }} >
                        <Grid item xs={12} sm={6}
                            sx={{ textAlign: { sx: "center", sm: "unset" } }}
                        >

                            <Button size="medium" variant="contained" color="primary"
                                sx={{
                                    color: ConstantStyles['white'],
                                    display: "inline-block",
                                    textAlign: "center",
                                    borderRadius: "0",
                                    width: { sx: "100%", sm: "25%" },
                                    margin: "0 5px",
                                    fontSize: "14px",

                                }}
                                style={{ backgroundColor: ConstantStyles.primary }}
                                disabled={count === 1 ? true : false}
                                onClick={(e) => modifyCards("Less")}
                            > - Lessen Cards</Button>

                            <Button size="medium" variant="contained" color="primary"
                                sx={{
                                    color: ConstantStyles['white'],
                                    display: "inline-block",
                                    textAlign: "center",
                                    borderRadius: "0",
                                    width: { sx: "100%", sm: "25%" },
                                    margin: "0 5px",
                                    fontSize: "14px"
                                }}
                                style={{ backgroundColor: ConstantStyles.primary }}
                                onClick={(e) => modifyCards("More")}
                            > + More Cards</Button>

                        </Grid>

                        <Grid item xs={12} sm={6} style={{ textAlign: "right", marginTop: "10px" }}>
                            <span>Sum: Php 0.00 </span>
                            <Button size="medium" variant="contained" color="primary"
                                onClick={handleSubmit}
                                sx={{
                                    color: ConstantStyles['white'],
                                    display: "inline-block",
                                    textAlign: "center",
                                    borderRadius: "0",
                                    width: { sx: "100%", sm: "25%" },
                                    margin: "0 5px",
                                    fontSize: "14px"
                                }}
                                style={{ backgroundColor: ConstantStyles.primary }}
                            > Continue</Button>
                        </Grid>

                    </Grid>
                    <br />

                </Card>
            </Grid >
        </Grid >
    )


}


export default LotterySection;