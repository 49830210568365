import React from 'react';
import Cookies from 'js-cookie'
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";

import {
    Button,
    Grid,
    Card,
    CardMedia,
    CardActions
} from '@mui/material';

const TopGamesAssets = [
    {
        "Title": "Play Powerball",
        "bgColor": ConstantStyles['white'],
        "gameUrl": `https://mega2ball-game.pages.dev/game/${Cookies.get('session-1') || ""}/${localStorage.getItem("username") || ""}`
    },
    {
        "Title": "Powerball Results",
        "bgColor": ConstantStyles['white'],
        "gameUrl": `https://mega2ball-game.pages.dev/game/${Cookies.get('session-1') || ""}/${localStorage.getItem("username") || ""}`

    },
    {
        "Title": "Powerball Information",
        "bgColor": ConstantStyles['white'],
        "gameUrl": `https://mega2ball-game.pages.dev/game/${Cookies.get('session-1') || ""}/${localStorage.getItem("username") || ""}`
    }
]

const ActionButtons = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    // }, []);

    return (
        <Grid container justifyContent="center" spacing={2} p={{ xs: 2, sm: 8 }} style={{ backgroundColor: ConstantStyles['light-grey'] }} >
            {TopGamesAssets.map((item, index) => (
                <Grid item xs={12} sm={4} key={index} >
                    <Card sx={{ maxWidth: "100%" }} style={{
                        backgroundColor: ConstantStyles.grey,
                        color: ConstantStyles.white
                    }}>
                        <CardActions sx={{ padding: 0 }}>
                            <Button size="medium" variant="contained" color="primary"
                                onClick={() => {

                                    if (Cookies.get('session-1') === "") {
                                        globalStates.alertType.set("error");
                                        globalStates.alertTitle.set("Error");
                                        globalStates.alertBody.set("Failed to retrieve user account.");
                                        globalStates.showAlert.set(true);
                                        setTimeout(() => {
                                            globalStates.showAlert.set(false);
                                        }, 3000);
                                    }
                                    else {
                                        window.location.href = item.gameUrl
                                    }
                                }


                                }
                                sx={{
                                    color: ConstantStyles['dark-grey'],
                                    display: "block",
                                    textAlign: "left",
                                    borderRadius: "0",
                                    width: "100%",
                                    fontSize: "16px",
                                    padding: "10px 20px"
                                }}
                                style={{ backgroundColor: item['bgColor'] }}
                            >{item.Title}</Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )


}


export default ActionButtons;