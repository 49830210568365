import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import Grid from '@mui/material/Grid';

import ConstantStyles from '../../../styles/ConstantStyles.json';
import bredas_logo from '../../../assets/img/bredas_logo.png';
import navItems from '../../../utils/NavItems.json';
import footerItems from '../../../utils/FooterItems.json';

import facebookIcon from '../../../assets/img/SocialMedia/facebook_icon.png';
import instagramIcon from '../../../assets/img/SocialMedia/instagram_icon.png';
import twitterIcon from '../../../assets/img/SocialMedia/twitter_icon.png';
const Footer = () => {
    const globalStates = useHookState(GlobalStates);
    // useEffect(() => {
    // }, []);

    return (
        <div style={{ backgroundColor: ConstantStyles['white'] }}>
            <Grid
                container
                spacing={2}
                p={8}
                style={{
                    paddingTop: "25px",
                    paddingBottom: "25px",
                    color: ConstantStyles['dark-grey'],
                    borderBottom: ConstantStyles['default-border']
                }}

            >
                <Grid item xs={12} sm={12}  >
                    <img src="https://lottopark.com/wp-content/themes/base/images/trusted.png" style={{ width: "100%", margin: "0 auto", display: "block" }} />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                p={{ xs: 2, sm: 8 }}
                style={{
                    paddingTop: "25px",
                    paddingBottom: "25px",
                    color: ConstantStyles['dark-grey'],
                    borderBottom: ConstantStyles['default-border']
                }}

            >
                <Grid item xs={12} sm={12}>
                    <p
                        style={{
                            color: ConstantStyles['dark-grey'],
                            fontSize: "12px"
                        }}>
                        <b>LottoPark is an independent service website offering online sale of lotto tickets and is not connected nor supervised by National Lottery, MUSL Camelot Plc, or any other provider of the products available on this website.</b> EuroMillions is a Services aux Loteries en Europe brand. National Lottery and Lotto are Camelot Group Plc. Brands.
                    </p>
                    <br />
                    <p
                        style={{
                            color: ConstantStyles['dark-grey'],
                            fontSize: "12px"
                        }}>
                        All lottopark.com products are operated by White Lotto B.V. registered address, Fransche Bloemweg 4, Willemstad, Curacao. A company licensed and regulated by the law of Curacao under the Master License Holder Curacao eGaming with license number 8048/JAZ. White Lto Limited (CY) (reg.number HE 413497) with a registered office located at Voukourestiou, 25 Neptun House, 1st floor, Flat/Office 11, Zakaki,3045, Limassol, Cyprus, is acting as an Agent on behalf of the license-holding entity White Lotto B.V.
                    </p>
                </Grid>
            </Grid>

            <Grid container spacing={2} p={8} style={{ paddingTop: "25px", paddingBottom: "0px", color: ConstantStyles['dark-grey'] }}>

                <Grid item xs={12} sm={12} className="listFooter" style={{ textAlign: "center" }}>
                    {navItems.map((item, index) => (
                        <a key={index} style={{ textDecoration: "none", color: "inherit", fontSize: "14px", margin: "0 16px" }} to={item.url}>{item.title}</a>
                    ))}
                </Grid>

                <Grid item xs={12} sm={12} style={{ textAlign: "center", marginTop: "0" }} >
                    <small style={{ color: ConstantStyles['dark-grey'] }}>&copy; BREDDAS 2022. ALL RIGHTS RESERVED.</small>
                </Grid>
            </Grid>
        </div >
    )


}


export default Footer;