import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";

import {
    Button,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActions
} from '@mui/material';

const TopGamesAssets = [
    {
        "Title": "Play Powerball",
        "bgColor": ConstantStyles['white']
    },
    {
        "Title": "Powerball Results",
        "bgColor": ConstantStyles['white']

    },
    {
        "Title": "Powerball Information",
        "bgColor": ConstantStyles['white']
    }
]

const GameInfo = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    // }, []);

    return (
        <Grid container justifyContent="center" spacing={2} p={{ xs: 2, sm: 8 }} style={{ backgroundColor: ConstantStyles['light-grey'], paddingTop: "0px" }} >

            <Grid item xs={12} sm={12}>
                <Card sx={{ maxWidth: "100%" }} style={{
                    backgroundColor: ConstantStyles.white,
                    color: ConstantStyles['dark-grey']
                }}>
                    <CardContent
                    // sx={{
                    //     padding: "5px 15px",
                    //     textAlign: "left",
                    //     backgroundColor: "#222131",
                    //     color: ConstantStyles.grey,
                    //     fontSize: { xs: "14px", md: "14px" },
                    //     minHeight: { xs: "unset", sm: "136px", md: "105px", lg: "70px", xl: "75px" }
                    // }}
                    >
                        <h4>How to play Powerball online?</h4>
                        <br />
                        <p>Powerball is one of the most popular American lotteries, but, until recently, it was only available to Americans playing locally. Thanks to LottoPark, you can buy <b>Powerball tickets online</b> from anywhere in the world.</p>
                        <br />

                        <p>Pick 5 numbers from 1 to 69 range plus an additional Powerball number from 1 to 26. To win the jackpot, you need to pick all 6 numbers correctly.</p>
                        <br />

                        <p>Decide how many lines or tickets you’d like to play in the next draw. Remember that each line should consist of different number combinations. The more lines you buy, the higher your <a href="#" style={{ color: ConstantStyles.secondary, textDecoration: "none" }}><b>chance of winning Powerball</b></a>!</p>
                        <br />

                        <p><b>Powerball online purchase</b> takes just a few simple steps, which may lead you to a big win. Play the American lotto online!</p>
                        <br />

                        <p>Remember, you can buy Powerball lottery tickets online 24 hours a day from any place in the world, from the comfort of your home in a convenient, fast and secure manner.</p>
                        <br />

                        <br />
                        <h4>Powerball ticket prices</h4>
                        <br />
                        <p>To check the Powerball ticket price, select the number of lines you’d like to participate with and pick your lucky numbers. The current Powerball ticket price will be displayed automatically below the table.</p>
                        <br />
                        <p>Powerball ticket prices on our site are the final ones and include the costs of a traditional paper ticket, bought by our representative on your behalf, plus our fee.</p>
                        <br />

                        <br />
                        <h4>Tips for Powerball Online</h4>
                        <br />
                        <p>Picking just 5 numbers correctly makes you eligible to win the 2nd tier prize: 1,000,000! If you’re lucky, only one <b>Powerball online ticket</b> can change your life!</p>
                        <br />
                        <p>By buying several lines, <a href="#" style={{ color: ConstantStyles.secondary, textDecoration: "none" }}><b>you can increase your chances of winning lottery</b></a>. Don’t hesitate to use the most commonly drawn numbers in the Powerball lottery.</p>
                        <br />
                        <p>Use the possibility to play when a rollover occurs. Powerball is a lottery with the highest rollovers, reaching up to several hundred millions of dollars.</p>
                        <br />
                        <p>If you want to increase your chances of winning – go for <b>Powerball online purchase</b> with a group! A bigger number of tickets gives you bigger possibilities when it comes to picking the numbers, and the overall Powerball tickets price will be split by the number of people in the group. It’s a simple and effective solution!</p>
                        <br />
                        <p><a href="#" style={{ color: ConstantStyles.secondary, textDecoration: "none" }}><b>How to check the current Powerball results</b></a>? You can easily do that on our website. We publish the official results right after the draw. Compare your lucky numbers with the official results to check if you have won.</p>
                        <br />
                        <p>Don’t hesitate, check the current rollover, pick your lucky numbers and <b>buy Powerball tickets online!</b></p>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )


}


export default GameInfo;