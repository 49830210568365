import { useState, useEffect, useMemo } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import ConstantStyle from '../../../styles/ConstantStyles.json'

import SiteLogo from '../../../assets/img/bredas_logo.png';
import navItems from '../../../utils/NavItems.json';

const drawerWidth = 240;
export default function DrawerAppBar(props) {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [cart, setCart] = useState((localStorage.getItem("cart")) ?? []);
    const [noCart, setNoCart] = useState(0);
    const [betAmount, setBetAmount] = useState(0);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const container = "";

    useEffect(() => {

        if (cart.length !== 0) {
            let parseCart = JSON.parse(cart);
            setNoCart(parseCart.cards.length)
            setBetAmount(parseCart.betAmount)
        }

    }, [localStorage.getItem("cart")]);

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <img src={SiteLogo} width="100"  onClick={() => window.location.href = "/"} />
            </Typography>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );


    return (
        <Box sx={{ display: 'block' }}>
            <CssBaseline />
            <AppBar component="nav" style={{ background: ConstantStyle["white"], boxShadow: "unset", boxShadow: ConstantStyle['default-box-shadow'], position: "relative" }}>
                <Toolbar>

                    <IconButton
                        color="plain"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon htmlColor={ConstantStyle.black} />
                    </IconButton>

                    <IconButton
                        color="plain"
                        aria-label="open drawer"
                        edge="start"
                        sx={{ mr: 2, ml: 0, display: { sm: 'none' }, width: "60%" }}
                    >
                        <img src={SiteLogo}  onClick={() => window.location.href = "/"} width="140" style={{ display: "block" }} />
                    </IconButton>

                    <Button sx={{
                        display: { sm: 'none' },
                        backgroundColor: ConstantStyle["white"],
                        color: ConstantStyle["black"],
                        margin: { xs: '0 8px', sm: '0 8px', md: '0 8px', xl: '0 8px' },
                        fontSize: "16px",
                        fontWeight: "normal",
                        "&:hover": {
                            backgroundColor: ConstantStyle["white"]
                        }
                    }}>
                        <div style={{ position: "relative", display: "inline-block", float: "right", marginRight: "10px" }} onClick={() => window.location.href = "/cart"}>
                            <span style={{
                                background: "green",
                                color: ConstantStyle.white,
                                width: "100%",
                                height: "85%",
                                borderRadius: "50%",
                                display: "block",
                                top: "-10px",
                                left: "15px",
                                position: "absolute",
                                fontSize: "13px"

                            }} onClick={() => window.location.href = "/cart"}>{noCart}</span>
                            <ShoppingCartIcon
                                fontSize='medium'
                            />
                        </div>
                    </Button>

                    <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'block' } }}>
                        <Grid container spacing={2} p={{ xs: 2, sm: 8 }} style={{ borderBottom: ConstantStyle['default-border'], paddingTop: "5px", paddingBottom: "5px" }} >
                            <Grid item xs={12} sm={4} >
                                <img src={SiteLogo} width="180" style={{ marginRight: "30px", cursor: "pointer" }}
                                    onClick={() => window.location.href = "/"} />
                            </Grid>
                            <Grid item xs={12} sm={8} >
                                <Grid container style={{ textAlign: "right" }} spacing={2} p={{ xs: 2, sm: 2, md: 1 }} >
                                    <Grid item xs={12} sm={12} >
                                        {localStorage.getItem("username") ?

                                            <Button
                                                onClick={() => {
                                                    window.location.href = "/account";
                                                }}
                                                sx={{
                                                    minWidth: "auto",
                                                    // padding: "10px 0",
                                                    backgroundColor: "transparent",
                                                    color: ConstantStyle["black"],
                                                    margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                                    fontSize: "16px",
                                                    fontWeight: "normal",
                                                    "&:hover": {
                                                        color: ConstantStyle["white"],
                                                        backgroundColor: ConstantStyle.secondary
                                                    }
                                                }}
                                            >
                                                Hello, <b> {localStorage.getItem("username")}</b>
                                            </Button>
                                            :
                                            <Button
                                                onClick={() => {
                                                    window.location.href = "/login";
                                                }}
                                                sx={{
                                                    minWidth: "130px",
                                                    padding: "10px 0",
                                                    backgroundColor: ConstantStyle.secondary,
                                                    color: ConstantStyle["white"],
                                                    margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                                    fontSize: "16px",
                                                    fontWeight: "normal",
                                                    "&:hover": {
                                                        backgroundColor: ConstantStyle.secondary
                                                    }
                                                }}
                                            >
                                                Login/Register
                                            </Button>
                                        }
                                        {/* <Button sx={{
                                            minWidth: "130px",
                                            padding: "10px 0",
                                            backgroundColor: ConstantStyle.primary,
                                            color: ConstantStyle["white"],
                                            margin: { xs: '0 8px', sm: '0 8px', md: '0 8px', xl: '0 8px' },
                                            fontSize: "16px",
                                            fontWeight: "normal",
                                            "&:hover": {
                                                backgroundColor: ConstantStyle.primary
                                            }
                                        }}>
                                            Sign up
                                        </Button> */}

                                        <Button sx={{
                                            minWidth: "200px",
                                            padding: "10px 0",
                                            backgroundColor: ConstantStyle["white"],
                                            color: ConstantStyle["black"],
                                            border: ConstantStyle['default-border'],
                                            margin: { xs: '0 8px', sm: '0 8px', md: '0 8px', xl: '0 8px' },
                                            fontSize: "16px",
                                            fontWeight: "normal",
                                            "&:hover": {
                                                backgroundColor: ConstantStyle["white"]
                                            }
                                        }}>
                                            <div style={{ position: "relative", display: "inline-block", float: "right", marginRight: "10px" }}>
                                                <span style={{
                                                    background: "green",
                                                    color: ConstantStyle.white,
                                                    width: "85%",
                                                    height: "85%",
                                                    borderRadius: "50%",
                                                    display: "block",
                                                    top: "-15px",
                                                    left: "17px",
                                                    position: "absolute"

                                                }}>{noCart}</span>
                                                <ShoppingCartIcon
                                                    fontSize='large'
                                                />
                                            </div>
                                            <div style={{ display: "inline-block", float: "left", marginLeft: "10px" }}
                                                onClick={() => window.location.href = "/cart"}
                                            >
                                                <span style={{ display: "block", fontSize: "20px", color: ConstantStyle.black, fontWeight: ConstantStyle['bold'] }}>{betAmount.toLocaleString("en-US", { style: "currency", currency: "PHP" })}</span>
                                                <span style={{ display: "block", color: ConstantStyle.secondary, fontWeight: ConstantStyle['bold'] }}>checkout</span>

                                            </div>
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{ textAlign: "center" }} spacing={2} p={{ xs: 2, sm: 2 }} >
                            <Grid item xs={12} sm={12} >
                                {navItems.map((item, index) => (
                                    <Button key={index} sx={{ color: ConstantStyle["black"], margin: { xs: '0 10px', sm: '0 10px', md: '0 20px', xl: '0 60px' }, fontSize: "16px", fontWeight: "normal" }}>
                                        {item.title}
                                    </Button>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </Toolbar>
                <Toolbar sx={{
                    display: { sm: 'none' },
                    backgroundColor: ConstantStyle['grey'],
                    padding: "15px",
                    textAlign: "center"
                }}>

                    {localStorage.getItem("username") ?

                        <div style={{ display: "block", width: "100%", margin: "0 auto" }}>
                            <Button
                                onClick={() => {
                                    window.location.href = "/account";
                                }}
                                sx={{
                                    minWidth: "auto",
                                    // padding: "10px 0",
                                    backgroundColor: "transparent",
                                    color: ConstantStyle["black"],
                                    margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    "&:hover": {
                                        color: ConstantStyle["white"],
                                        backgroundColor: ConstantStyle.secondary
                                    }
                                }}
                            >
                                Hello, <b> {localStorage.getItem("username")}</b>
                            </Button>
                        </div>
                        :
                        <div style={{ display: "block", width: "100%", margin: "0 auto" }}>
                            <Button
                                onClick={() => {
                                    window.location.href = "/login";
                                }}
                                sx={{
                                    minWidth: "130px",
                                    padding: "10px 0",
                                    backgroundColor: ConstantStyle.secondary,
                                    color: ConstantStyle["white"],
                                    margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    "&:hover": {
                                        backgroundColor: ConstantStyle.secondary
                                    }
                                }}
                            >
                                Login/Register
                            </Button>
                        </div>
                    }
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, color: ConstantStyle["grey"], backgroundColor: ConstantStyle["white"] },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );

}

