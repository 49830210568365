import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../../utils/GlobalStates';
import ConstantStyles from "../../../../../styles/ConstantStyles.json";
import CustomTextField from "../../../../shared/CustomTextField";
import CustomButton from "../../../../shared/CustomButton";
import CustomAutocompleteSelect from "../../../../shared/CustomAutocompleteSelect";
import moment from "moment";
import {
    Grid
} from '@mui/material';
import { UpdateKYC, ImageUpload } from "../../../../../utils/Endpoints";
import SourceOfIncome from "../../../../../utils/SourceOfIncome.json"

const Settings = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthday, setBirthday] = useState("");
    const [receipt, setReceipt] = useState(null);
    const [uploadedId, setUploadedId] = useState(null);
    const [primaryNo, setPrimaryNo] = useState(null);
    const [primaryType, setPrimaryType] = useState(null);
    const [address, setAddress] = useState(null);
    const [age, setAge] = useState(null);
    const [income, setIncome] = useState(null);

    useEffect(() => {
        var years = moment().diff(props?.player.birth_date, 'years', false);
        setFirstName(props?.player.first_name || "")
        setMiddleName(props?.player.middle_name || "")
        setLastName(props?.player.last_name || "")
        setBirthday(props?.player.birth_date || "")
        setAddress(props?.player.address || "")
        setIncome({ value: props?.player.income, label: props?.player.income })
        setAge(years || "")
        setUploadedId(props?.player.primary_id_file || "-")
        setPrimaryNo(props?.player.primary_id_no || "0")
        setPrimaryType(props?.player.primary_id_type_id || 0)
    }, [props]);


    const incomeSelect = useMemo(() => {
        return SourceOfIncome.map(col => {
            return { value: col.value ?? "", label: col.label ?? "" }
        });
    }, []);

    const hanldeSubmit = () => {
        if (firstName === "" || lastName === "" || birthday === "") {

            globalStates.alertBody.set("Kindly fillup all required field '*' ");
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");
            globalStates.showAlert.set(true);

        }
        else {
            if (props.type === "id") {
                if (receipt) {
                    ImageUpload('primary_id_file', receipt[0])
                        .then(response => {

                            let body = {
                                "username": props?.player.username,
                                "data": {
                                    "first_name": firstName,
                                    "middle_name": middleName,
                                    "last_name": lastName,
                                    "birth_date": birthday,
                                    "address": address,
                                    "income": income?.label,
                                    "primary_id_no": "0",
                                    "primary_id_type_id": 0,
                                    "primary_id_file": response.data.date.primary_id_file ?? "-",
                                }
                            }
                            update(body)
                        })
                        .catch(error => {
                            // console.log(error);
                            setIsLoggingIn(false);
                            globalStates.alertType.set("error");
                            globalStates.alertTitle.set("Error");
                            globalStates.showAlert.set(true);

                        })
                }
                else if (uploadedId !== "") {
                    if (primaryNo !== "" && primaryType !== "") {
                        let body = {
                            "username": props?.player.username,
                            "data": {
                                "first_name": firstName,
                                "middle_name": middleName,
                                "last_name": lastName,
                                "birth_date": birthday,
                                "address": address,
                                "income": income?.label,
                                "primary_id_no": "0",
                                "primary_id_type_id": 0,
                                "primary_id_file": uploadedId,
                            }
                        }
                        update(body)
                    }
                    else {
                        globalStates.alertType.set("error");
                        globalStates.alertTitle.set("Error");
                        globalStates.alertBody.set("Kindly Fillup all required field(s).");
                        globalStates.showAlert.set(true);
                    }
                }
            }
            else if (props.type === "notid") {
                let body = {
                    "username": props?.player.username,
                    "data": {
                        "first_name": firstName,
                        "middle_name": middleName,
                        "last_name": lastName,
                        "birth_date": birthday,
                        "address": address,
                        "income": income?.label,
                        "primary_id_no": "0",
                        "primary_id_type_id": 0,
                        "primary_id_file": uploadedId,
                    }
                }
                update(body)
            }
            else {
                alert("here")
            }
            let formData = new FormData();
            formData.append(receipt?.[0], formData);

            setTimeout(() => {
                globalStates.showAlert.set(false);
            }, 2000);
        }
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    const update = (body) => {
        UpdateKYC(body)
            .then(response => {
                if (response.data.status) {
                    globalStates.alertBody.set("Detail(s) Successfully updated");
                    globalStates.alertType.set("success");
                    globalStates.alertTitle.set("Success");
                    globalStates.showAlert.set(true);

                }
            })
            .catch(error => {
                console.log(error)
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.alertBody.set("Failed to retrieve player details.");
                globalStates.showAlert.set(true);

                setTimeout(() => {
                    globalStates.showAlert.set(false);
                }, 3000);
            })
            .finally(() => {
                // setIsTableLoading(false);
                props.func(Date.now());
            });
    }

    return (
        <Grid container
            spacing={1}
            justifyContent="center"
            p={{ xs: 2, sm: 2 }}
        >
            <Grid item xs={12} sm={12} sx={{ display: props.type === "id" ? "unset" : "none" }}>

                <p style={{ fontSize: "16px", marginBottom: "5px", display: props.type === "id" ? "unset" : "none" }}>Primary ID <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>

                <input
                    style={{ fontSize: "16px", marginBottom: "15px", display: props.type === "id" ? "block" : "none" }}
                    type="file"
                    onChange={(event) => setReceipt(event.target.files[0] === undefined ? null : event.target.files)}
                />

                <p style={{ fontSize: "16px", marginBottom: "5px", display: "none" }}>ID No <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>

                <CustomTextField
                    value={primaryNo}
                    onChange={setPrimaryNo}
                    placeholder={"Enter ID Number"}
                    type="number"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px",
                        display: props.type === "id" ? "none" : "none"
                    }}
                />
                <CustomTextField
                    value={primaryType}
                    onChange={setPrimaryType}
                    placeholder={"Enter Type ID"}
                    type="number"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px",
                        display: props.type === "id" ? "none" : "none"
                    }}
                />


                {uploadedId !== null ? <img src={uploadedId} style={{ fontSize: "16px", marginBottom: "5px", width: "100%", display: props.type === "id" ? "unset" : "none" }} /> : null}


            </Grid>
            <Grid item xs={12} sm={12}>                
            <p style={{
                fontSize: "16px", marginBottom: "5px",
                display: props.type === "notid" ? "unset" : "none"
            }}>Firstname <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                <CustomTextField
                    value={firstName}
                    onChange={setFirstName}
                    placeholder={"Enter Firstname"}
                    type="text"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px",
                        display: props.type === "notid" ? "unset" : "none"
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ display: props.type === "notid" ? "unset" : "none" }}>
                <p style={{
                    fontSize: "16px", marginBottom: "5px",
                    display: props.type === "notid" ? "unset" : "none"
                }}>Middlename </p>
                <CustomTextField
                    value={middleName}
                    onChange={setMiddleName}
                    placeholder={"Enter Middlename"}
                    type="text"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px",
                        display: props.type === "notid" ? "unset" : "none"
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ display: props.type === "notid" ? "unset" : "none" }}>
                <p style={{
                    fontSize: "16px", marginBottom: "5px",
                    display: props.type === "notid" ? "unset" : "none"
                }}>Lastname <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                <CustomTextField
                    value={lastName}
                    onChange={setLastName}
                    placeholder={"Enter Lastname"}
                    type="text"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px",
                        display: props.type === "notid" ? "unset" : "none"
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ display: props.type === "notid" ? "unset" : "none" }}>
                <p style={{
                    fontSize: "16px", marginBottom: "5px",
                    display: props.type === "notid" ? "unset" : "none"
                }}>Birthday <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                <CustomTextField
                    value={birthday}
                    onChange={(e) => {
                        var years = moment().diff(e, 'years', false);
                        setBirthday(e)
                        setAge(years)
                    }}
                    type="date"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px",
                        display: props.type === "notid" ? "unset" : "none"
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ display: props.type === "notid" ? "unset" : "none" }}>
                <p style={{
                    fontSize: "16px", marginBottom: "5px",
                    display: props.type === "notid" ? "unset" : "none"
                }}>Age </p> 

                <CustomTextField
                    value={age}
                    disabled={false}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px",
                        display: props.type === "notid" ? "unset" : "none"
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ display: props.type === "notid" ? "unset" : "none" }}>
                <p style={{
                    fontSize: "16px", marginBottom: "5px",
                    display: props.type === "notid" ? "unset" : "none"
                }}>Address </p>
                <CustomTextField
                    value={address}
                    onChange={setAddress}
                    placeholder={"Enter Address"}
                    type="text"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px",
                        display: props.type === "notid" ? "unset" : "none"
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ display: props.type === "notid" ? "unset" : "none" }}>
                <p style={{
                    fontSize: "16px", marginBottom: "5px",
                    display: props.type === "notid" ? "unset" : "none"
                }}>Source of Income </p>
                <CustomAutocompleteSelect
                    options={incomeSelect}
                    value={income?.value}
                    onChange={setIncome}
                    placeholder={"Select option"}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <CustomButton
                    buttonLabel={"Submit"}
                    onClick={() => hanldeSubmit()}
                    type="submit"
                    disabled={parseInt(age) >= 0 ? false : true}
                    customSX={{
                        width: "25%",
                        float: "right",
                        marginTop: props.type === "notid" ? "10%" : "0",
                        padding: "6px 20px",
                        color: ConstantStyles.white,
                        border: ConstantStyles['default-border'],
                        verticalAlign: "top",
                        "&:hover": {
                            backgroundColor: ConstantStyles.primary,
                            color: ConstantStyles.white,
                            boxShadow: "none"
                        },
                    }}
                />
            </Grid>
        </Grid >
    )


}


export default Settings;