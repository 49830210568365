import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";

import KYC from "./Section/KYC";
import {
    Box
} from '@mui/material';

const AccountSettings = () => {
    const globalStates = useHookState(GlobalStates);
    const [activeTab, setActiveTab] = useState("0");
    // useEffect(() => {
    // }, []);
    const handleChange = (event, newValue) => {
        setActiveTab(event.target.id);
    };


    return (
        <div style={{ backgroundColor: ConstantStyles['light-grey'] }}>
            <Box sx={{
                backgroundColor: ConstantStyles['light-grey'],
                margin: "0 auto",
                maxWidth: "1440px",
                // paddingLeft: { xs: "0px", sm: "0px", md: "220px" },
                minHeight: "850px",
                paddingTop: "20px",
                paddingBottom: "140px"
            }} >
                <KYC />
            </Box>
        </div>
    )


}


export default AccountSettings;