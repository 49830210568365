import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Cookies from 'js-cookie';
// shared components
import CustomAlert from "./components/shared/CustomAlert";
import CustomCircularProgress from './components/shared/CustomCircularProgress';
// components
import Header from './components/shared/general/Header';
import Footer from './components/shared/general/Footer';
import Login from './components/modules/authentication/Login';
import AccountSettings from './components/modules/Accounts/AccountSettings';
import Cart from './components/modules/BetCart/Cart';

// modules
import Homepage from './components/modules/Home/Homepage';
// style 
import ThemeStyles from "./styles/ThemeStyles";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
// global states
import GlobalStates from './utils/GlobalStates';
// general components

function App(props) {
	const globalStates = useHookState(GlobalStates);

	const [theme, setTheme] = useState(ThemeStyles);

	useEffect(() => {

		if (!Cookies.get('session-1') || Cookies.get('session-1') === "") {
			localStorage.clear()
		}

		if (window.screen.width <= 640) {
			globalStates.isMobile.set(true);
		}
		else {
			globalStates.isMobile.set(false);
		}
	}, [window.screen.width]);

	return (
		<ThemeProvider theme={theme}>
			{window.location.pathname !== "/login" ? <Header /> : ""}
			<BrowserRouter basename={props.baseUrl}>

				{localStorage.getItem("username") ?
					<Routes>
						<Route path="/" element={<Homepage />} />
						<Route path="/account" element={<AccountSettings />} />
						<Route path="/cart" element={<Cart />} />
					</Routes>
					:
					<Routes>
						<Route path="/" element={<Homepage />} />
						<Route path="/Login" element={<Login />} />
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				}
			</BrowserRouter>

			<CustomAlert />
			{window.location.pathname !== "/login" ? <Footer /> : ""}
		</ThemeProvider>
	);
}

export default App;
