import axios from 'axios';
import Cookies from 'js-cookie'
// let rootUrl = "api root";
// let apiKey = "api key";
let baseUrl = "https://1tfml0g4lk.execute-api.ap-southeast-1.amazonaws.com";
let bingoGame = "https://uat.888bingo.ph/api/games/1671070084897";

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.interceptors.request.use(config => {
    config.headers['session-1'] = Cookies.get('session-1');
    return config;
});

export function GetLiveGames() {
    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/games`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

export function LaunchLiveGame(params) {
    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/games/live-bingo/${params}`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };
    return axios(authOptions);
}


export function GetBingoGames() {
    const authOptions = {
        method: "get",
        url: `${bingoGame}`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

export function LaunchBingoGame(params) {
    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/games/888bingo/${params}`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };
    return axios(authOptions);
}

// POST request OTP
export function LoginPlayer(body) {
    const authOptions = {
        method: "post",
        url: `${baseUrl}/api/auth/login`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}
// POST request OTP
export function SendOTP(body) {
    const authOptions = {
        method: "post",
        url: `${baseUrl}/api/otp/send`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

// POST request OTP
export function VerifyOTP(body) {
    const authOptions = {
        method: "post",
        url: `${baseUrl}/api/otp/verify`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

//Get wallet
export function GetWallet() {
    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/wallet/balance`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

//Get gwallet
export function GetGcashWallet() {
    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/gcash/refresh`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

//Get kyc
export function GetKYC(params) {
    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/player/one`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

//Update kyc
export function UpdateKYC(body) {

    const authOptions = {
        method: "put",
        data: body,
        url: `${baseUrl}/api/kyc`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

// POST withdrawl receipt
export function ImageUpload(src, receipt) {
    var bodyFormData = new FormData();
    // let bodyFormData = new FormData();
    // bodyFormData.append(receipt, bodyFormData)
    let type = null;

    if (src === 'withdraw_receipt') {
        type = 'withdraw-receipt'
        bodyFormData.append('withdraw_receipt', receipt);
    } else if (src === 'primary_id_file') {
        type = 'identification-card'
        bodyFormData.append('primary_id_file', receipt);
    }

    if (!type) {
        throw new Error('not available');
    };

    return axios.post(`${baseUrl}/api/upload?type=${type}`, bodyFormData)
}

//Post Withdraw
export function Withdrawal(body) {

    const authOptions = {
        method: "post",
        data: body,
        url: `${baseUrl}/api/withdraw`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}

//Post Withdraw
export function DepositAmount(amount) {

    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/gcash/deposit?amount=${amount}`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    // return axios.post(`${baseUrl}/api/upload?type=${type}`, bodyFormData)
    return axios(authOptions);
}

//get Withdraw
export function GetTransactionsWithdraw(body) {

    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/withdraw/paginate`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    // return axios.post(`${baseUrl}/api/upload?type=${type}`, bodyFormData)
    return axios(authOptions);
}


//get Deposit
export function GetTransactionsDeposit(body) {

    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/deposit/paginate`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    // return axios.post(`${baseUrl}/api/upload?type=${type}`, bodyFormData)
    return axios(authOptions);
}


//get bet history
export function GetGameHistory() {

    const authOptions = {
        method: "get",
        url: `${baseUrl}/api/wager-result/paginate?page=1&size=99999`,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    };

    return axios(authOptions);
}



// POST login user
// export function LoginUser(body) {
//     const authOptions = {
//         method: "post",
//         url: `${rootUrl}/api/authenticate/sign-in`,
//         data: body,
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": apiKey
//         }
//     };

//     return axios(authOptions);
// }

