import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import {
    Grid
} from '@mui/material';

const ImageWithCTA = () => {
    const globalStates = useHookState(GlobalStates);
    
    // useEffect(() => {
    // }, []);

    return (
        <Grid container spacing={2} p={{ xs: 0, sm: 8 }} style={{ paddingTop: "0", paddingBottom: "0" }} >
            <Grid item
                xs={12}
                style={{ position: "relative" }}
                sx={{
                    padding: { xs: "0", sm: "0" }
                }}
            >
                <img src="https://lottopark.com/wp-content/uploads/sites/2/bonus-lottopark.png" id="imageSection" style={{ width: "100%", display: "block", margin: "0 auto" }} />
                {/* <div className='cta_part'>
                    <Typography variant="h2" gutterBottom style={{ color: ConstantStyles.white, marginBottom: "0px" }}>
                        Revolutionizing
                    </Typography>
                    <Typography variant="h2" gutterBottom style={{ color: ConstantStyles.primary, marginBottom: "135px", fontWeight: "600" }}>
                        ELECTRONIC BINGO
                    </Typography>
                    <Button size="medium" variant="contained" color="primary"
                        sx={{
                            color: ConstantStyles.white,
                            display: "inline",
                            borderRadius: "50px",
                            width: "40%",
                            marginRight: "10%",
                            fontSize: "16px",
                            padding: "5px"
                        }}>OUR GAMES</Button>

                    <Button size="medium" variant="contained" sx={{
                        color: ConstantStyles.white,
                        background: "transparent",
                        border: "1px solid #fff",
                        display: "inline",
                        borderRadius: "50px",
                        width: "40%",
                        marginRight: "5%",
                        fontSize: "16px",
                        padding: "5px",
                        "&:hover": {
                            background: "transparent"
                        }
                    }}>CONTACT US</Button>
                </div> */}
            </Grid>
        </Grid>
    )


}


export default ImageWithCTA;